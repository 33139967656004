import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PopularGameList } from '../helpers/data';
import { useSelector } from 'react-redux';

const PopularSection = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [category] = useState('Top Picks');
    const countryCode = useSelector(state => state.countryCode.value);

    function chunkArray(array, size) {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    }

    const filteredGames = PopularGameList.games.filter(game =>
        (game.countryCode === 'US' ? countryCode === 'US' : countryCode !== 'US') && (game.category === category)
    );

    const gameChunks = chunkArray(filteredGames, 3);

    function truncateText(text, charLimit) {
        if (text.length > charLimit && isMobile) {
            return text.substring(0, charLimit) + "...";
        }
        return text;
    }

    return (
        <div id='popularSection' className='xl:mt-[100px] lg:mt-20 mt-16'>
            <h3 className='text-custom-1 dark:text-white md:text-[40px] text-2xl font-bold md:leading-[44px] leading-8 m-0'>
                { PopularGameList.heading }
            </h3>
            <div className='flex md:gap-8 gap-4 overflow-x-auto no-scrollbar mt-10'>
                { gameChunks.map((chunk, chunkIndex) => (
                    <div key={`chunk-${chunkIndex}`} className='flex flex-col flex-item gap-8 xl:w-[428px] md:w-[448px] w-[307px]'>
                        {chunk.map((game, gameIndex) => (
                            <a
                                key={`game-${gameIndex}`}
                                href={ game.url }
                                target='_blank'
                                rel='noreferrer'
                            >
                                <div className='flex md:gap-6 gap-4'>
                                    <img
                                        src={ game.icon }
                                        alt={ game.altText }
                                        className='rounded-lg'
                                        width={100}
                                        height={100}
                                    />
                                    <div className='flex flex-col gap-2'>
                                        <h3 className='text-custom-1 dark:text-white text-2xl font-bold leading-8 m-0'>{ truncateText(game.heading, 14) }</h3>
                                        <p className='text-custom-2 dark:text-custom-6 text-base font-normal leading-[27.84px] m-0'>
                                            { game.text }
                                        </p>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PopularSection
