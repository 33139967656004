import React, { useEffect } from "react";
import TextContent from "./TextContent";
import Labels from "./Labels";
import CasinoGames from "./CasinoGames";
import Web3Games from "./Web3Games";
import NewsSection from "./NewsSection";
import NewsLetter from "./NewsLetter";
import NewGenGames from "./NewGenGames";
import Banner from "./Banner";
import PopularSection from "./PopularSection";
import { verseGameList, web3GameList } from "../helpers/data";
import { useDispatch, useSelector } from "react-redux";
import { fetchGames } from "../store/gamesSlice";
import { fetchEarnAllianceGames } from '../store/earnAllianceSlice';
import ReviewBanner from "./ReviewBanner";
import EarnAllianceGames from "./EarnAllianceGames";
import NewsLetterBanner from "./NewsLetterBanner";

const Home = () => {
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.countryCode.value);

  useEffect(() => {
    dispatch(fetchGames());
    dispatch(fetchEarnAllianceGames());
  }, [dispatch]);

  return (
    <React.Fragment>
      <TextContent />
      <Labels />
      <Banner />
      <CasinoGames
        title="Recommended Games"
        categoryId="1"
        sectionId="recommendedGames"
        url="https://srt.bitcoin.com/games-app"
        showViewButton={true}
      />
      <PopularSection />
      <Web3Games
        title="Verse Games"
        GameList={verseGameList}
        sectionId="verseGames"
        url="https://srt.bitcoin.com/games-verse"
      />
      <NewsLetter
        imageUrl={
          countryCode === "US"
            ? "/images/stake-new-us-banner.png"
            : "/images/stake-new-banner.png"
        }
        url={
          countryCode === "US"
            ? "https://srt.bitcoin.com/stake-games-banner-us"
            : "https://srt.bitcoin.com/stake-games-banner/"
        }
      />
      <NewGenGames
        title="Web3 Games"
        GameList={web3GameList}
        sectionId="web3Games"
        url={
          countryCode === "US"
            ? "https://srt.bitcoin.com/stake-games-banner-us/"
            : "https://srt.bitcoin.com/stake-games-banner/"
        }
      />
      <CasinoGames
        title="Slots"
        categoryId="3"
        sectionId="slotsGames"
        url="https://srt.bitcoin.com/games-app"
        showViewButton={true}
      />
      <EarnAllianceGames 
        title="Web3 Games by Earn Alliance"
        sectionId="earnAllianceGames"
        url="https://www.earnalliance.com/games?utm_source=bitcoincom"
      />
      <NewsLetter
        imageUrl="/images/vers-scratch-win-advertise-banner.webp"
        url="https://srt.bitcoin.com/games-scratch"
      />
      <CasinoGames
        title="Table"
        categoryId="4"
        sectionId="tableGames"
        url="https://srt.bitcoin.com/games-app"
        showViewButton={false}
      />
      <CasinoGames
        title="Live Casino"
        categoryId="6"
        sectionId="liveCasinoGames"
        url="https://srt.bitcoin.com/games-app"
        showViewButton={false}
      />
      <NewsLetterBanner />
      <ReviewBanner />
      <NewsSection
        title="Latest News"
        url="https://srt.bitcoin.com/games-news"
      />
    </React.Fragment>
  );
};

export default Home;
